@import "./colors.scss";

.container {
  max-width: 1824px;
  width: 100%;
  padding: 0 3rem 1.25rem 3rem; // top right bottom left
  display: flex;
  justify-content: center;
}

body {
  text-align: center;
}

h1 {
  color: $textBlue !important;
  font-size: 1.5625rem;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payroll-wrapper {
  padding-top: 0;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.employee-documents-content {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.documents-list {
  width: 100%;
  margin-top: 15px;
}

.employee-documents-wrapper {
  display: inline-block;
  max-width: 85rem;
  padding: 20px;
  text-align: center;
  width: 100%;
  min-width: 60rem;
}

@media (max-width: 62rem) {
  .employee-documents-wrapper {
    max-width: 100%;
    padding: 20px;
    min-width: 0;
  }
}

.links {
  display: inline-block;
  position: relative;
  margin-left: 16.5rem;
  margin-right: 3rem;
  margin-top: 0.5rem;
  color: $anequimBlue;
}

.links a {
  color: $anequimBlue !important;
  text-decoration: none;
}

@media (max-width: 62rem) {
  .links {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.links .arrow {
  margin-left: 0.5rem;
  color: $arrowOrange;
}

.info-link {
  cursor: pointer;
}

.administration-link {
  margin-right: 2.5rem;
}

.disabled-link,
.disabled-link .arrow {
  color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

.info-tooltip {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.9rem;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  color: $black;
}

.info-link:hover .info-tooltip {
  display: block;
}

.documents-filter {
  margin-bottom: 1.125rem;
  margin-right: calc(2.25rem - 3px);
  float: right;
}

.input-group {
  position: relative;
}

.filter-input {
  border-color: $bgGrey;
  height: 2.25rem;
  max-width: 11.25rem !important;
}

.filter-reset {
  background-color: $btnBlue !important;
  border-color: $btnBlue !important;
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: 98%;
  z-index: 3;
}

.filter-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.filter-reset:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 154, 197, 0.5) !important;
}

.documents-table {
  text-align: left;
}

.documents-table .rdt_TableHeadRow {
  background-color: $anequimBlue !important;
  color: $white;
  border-top: 1px solid $borderGrey;
  border-bottom: 1px solid $borderGrey;
}

.pencil-icon {
  width: 21px;
}
