@import "./colors.scss";

/* Text alignment for body */
body {
  text-align: center;
}

/* Styling h1 tag */
h1 {
  color: $anequimBlue;
  text-align: center;
}

/* Styling modal */
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.modal .modal-content {
  padding: 20px 20px 20px 20px;
  -webkit-animation-name: modal-animation;
  -webkit-animation-duration: 0.5s;
  animation-name: modal-animation;
  animation-duration: 0.5s;
}

@-webkit-keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes modal-animation {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

//.sidebar{
//  display: inline-block;
//}

.payroll-content{
  display: inline-block;
  width: 100%;
}

.payroll-detail-list {
  width: 100%;
  border: 2px solid lightgray;
  margin-top: 15px;
}

.payroll-detail-list span {
  font-weight: bold;
}

.col-md-6{
  display: inline-block;
}

.payroll-wrapper {
  display: inline-block;
  max-width: 1140px;
  padding: 20px;
}

.payroll-content label {
  font-weight: bold;
}

.payroll-content .field {
  display: block;
  text-align: left;
}

.payroll-header-content {
  border: 2px solid lightgray;
}

.payroll-header-content h1 {
  border-bottom: 2px solid lightgray;
}

.payroll-detail-list .check-detail-link {
  text-decoration: none;
}

.field span {
  padding-left: 5px;
}
