@import './colors';

.login {
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  width: 15.15rem;
  margin-left: 0.375rem;
}

.vl {
  border-left: 1px solid $lineBlue;
  height: 48px;
  position: relative;
  left: 50%;
  top: 0;
  margin-top: 1.5rem;
  margin-bottom: 2.0625rem;
}

.title {
  color: $titleBlue;
  font-size: 1.6875rem;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 5.125rem;
}

.login-box {
  margin: auto;
}

.primary-login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15.3125rem;
  height: 2.9375rem;
  padding: 1rem 0.6875rem;
  border-radius: 1.875rem;
  background: $btn1Blue;
  color: $white;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  letter-spacing: -0.05rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.primary-login-btn:hover {
  color: $white;
}

.secondary-login-btn {
  display: flex;
  width: 15.3125rem;
  height: 2.9375rem;
  padding: 1rem 0.6875rem 1rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 1.875rem;
  background: #D5F0FF;
  color: $secondaryBlue;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  letter-spacing: -0.05rem;
}

.secondary-login-btn:hover {
  color: $secondaryBlue;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem $lineBlue;
}

.compact-logo {
  height: 2.1875rem;
}

.anequim-compact-logo {
  margin-left: 0.63rem;
}
