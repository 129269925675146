@import './colors.scss';

.spinner-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.spinner-box {
  padding: 30px;
  margin: 0 auto;
  border: none;
  min-height: 250px;
  min-width: 250px;
}