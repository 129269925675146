@import "./colors.scss";

.client-selector {
  position: absolute;
  top: 45%;
  left: 45%;
}

.modal-x:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  vertical-align: middle;
}

.service-issue-detail {
  position: absolute;
  width: 50%;
  background-color: #fff;
  top: 20%;
  left: 25%;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 5px 15px RGB(0 0 0 / 30%);
  cursor: pointer;
  padding: 15px;
}

.card-title {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 4em;
}

.form-group div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-group label {
  font-weight: bold;
}

.form-group div.select-align {
  display: block;
  width: 100%;
  text-align: center;
}

.form-group-left-aligned {
  align-items: flex-start;
  text-align: left;
}

.soporte-title {
  margin-bottom: 1rem;
}

.vertical-line {
  height: 100%;
  border-left: 2px solid $lineBlue;
}

.red-border {
  border: 4px solid red;
}

.help-center-container {
  margin: 10px 0;
  padding: 0 20px;
}

#service-issues-wrapper {
  max-width: 95%;
  padding: 20px;
  text-align: center;
}

.service-issue-card {
  margin: 10px 0;
}

#issue-list-container {
  height: 65vh;
  overflow: scroll;
  overflow-x: hidden;
}

#si-comment-button-container {
  padding: 5px 5px;
  align-self: center;
  height: 15vh;
}

.issue-card-title {
  line-height: 2em !important;
}

.si-header {
  color: $textBlue;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.si-body {
  color: $darkGray;
  text-align: center;
  margin-top: 1rem;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.crear-ticket-button {
  display: flex;
  width: 14.9375rem;
  padding: 0.8125rem 1.375rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.875rem;
  background: $coral;
  color: $white;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.05rem;
  border: none;
  margin: 2rem auto 0;

  &:hover {
    background: darken($coral, 30%);
  }
}

.modal-design .modal-content {
  border-radius: 1.25rem;
  background-color: #D5F0FF;
  border: none;
}

.cancel-button, .send-button {
  width: 11.1875rem;
  height: 3.25rem;
  border-radius: 1.875rem;
  border: none;
  transition: background-color 0.3s ease;
}

.cancel-button {
  width: 11.1875rem;
  height: 3.25rem;
  border-radius: 1.875rem;
  background-color: #BAE6FF;
  color: #444;
}

.send-button {
  width: 11.1875rem;
  height: 3.25rem;
  border-radius: 1.875rem;
  background-color: #FF6A4D;
  color: #FFF;
}

.cancel-button:hover {
  color: #444;
  background-color: #9DC9E8;
}

.send-button:hover {
  background-color: #E95636;
}

.support-ticket-title {
  color: #3E7296;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.category-message-headers {
  color: #3E7296;
  text-align: left;
  font-family: 'Raleway', sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.modal-backdrop.show {
  opacity: 0.2 !important;
}

.category-select {
  border-radius: 0.625rem;
  height: 2.5625rem !important;
}

.message-textarea {
  border-radius: 0.625rem;
  height: 8.6875rem !important;
}
