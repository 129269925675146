.admin .login-header {
  text-align: center;
}

.admin {
  max-width: 50%;
  margin-left: 25%;
  margin-top: 15%;
}

