.red-trashcan-icon {
  width: 21px;
  padding-top: 7px;
}

.check-box-container {
  display: flex;
  justify-content: center;
}

.is-active-check-box > span {
  border: 0px;
  background-color: transparent;
}

.inactive-row {
  text-decoration: line-through;
  text-decoration-thickness: 0.15em;
}

body > div.file-permissions-modal-form.modal > div {
  width: 90%;
}

.document-permissions-container {
  width: 100%;
}

.document-permissions-thead {
  background: #5A9AC5;
  color: white;
  font-size: large;
}

.table th + th {
  border-left:1px solid;
}

.table td + td {
  border-left:1px solid;
  border-left-color: #dee2e6;
}