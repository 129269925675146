@import "bootstrap";
@import "./assets/styles/colors.scss";

body, html, #root {
  height: 100%;
}

#root-container {
  height: 100%;
}

.anequim-pager, .anequim-pager:visited, .anequim-pager:link {
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border: none;
  box-shadow: none;
  margin: 10px;
}

.anequim-pager:hover {
  color: #61dafb;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.anequim-pager:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus, .anequim-pager:focus {
  color: rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.anequim-pager:disabled {
  color: #BCBEC0;
  background-color: #fff;
  border: none;
  box-shadow: none;
}

.anequim-button:disabled, .anequim-button:disabled:hover {
  color: #BCBEC0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  box-shadow: none;
}

.anequim-button, anequim-button:visited, anequim-button:visited:focus, .btn-primary:focus, .btn-primary.focus {
  color: #000;
  background-color: #FF6A4D;
  border: none;
  box-shadow: none;
}

.anequim-button:hover {
  color: #000;
  background-color: #F79340;
  border: none;
  box-shadow: none;
}

.anequim-button:active, .anequim-button:active:focus {
  color: #fff;
  background-color: #FF6A4D;
  border: none;
  box-shadow: none;
}

.breadcrumb-nav > * {
  background-color: #BCBEC0;
}

.breadcrumb-nav ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  color: #333;
  margin: auto 6px;
  -webkit-user-select: none;
  user-select: none;
}

.button-padding {
  padding: 15px;
}

.justify-center {
  justify-content: center;
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.outer-row {
  margin-left: 0;
  margin-right: 0;
}

.react-datepicker-wrapper {
  width: 100%
}

.paging-div {
  padding-top: 50px;
}


.pdf-max-height, .pdf-max-height * {
  max-height: 600px;
}

.select-option {
  margin: auto;
  max-width: fit-content;
  padding: 15px 0 0 0;
}

.overlay-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: gray;
  opacity: 50%;
  cursor: default;
}

#error-modal {
  position: relative;
  left: 40%;
  top: 50%;
}

@media (min-width: 600px) {
  body > div.contactModalForm.modal > div {
    width: 50vw;
    display: flex;
  }
}

@media (min-width: 260px) and (max-width: 600px) {
  body > div.contactModalForm.modal > div {
    width: 90vw;
    display: flex;
  }
}

.legal-banner {
  font-family: Raleway, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $white;
  background-color: $btn1Blue;
  width: 100%;
  position: relative;
  bottom: -1.75rem;
}

.legal-banner-content {
  width: 100%;
  max-width: 86rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legal-banner-item-left {
  width: -moz-fit-content;
  width: fit-content;
  padding: 2rem 1rem 1.5rem 3rem; // top right bottom left
  text-align: left;
}

.legal-banner-item-right {
  width: -moz-fit-content;
  width: fit-content;
  padding: 2rem 3rem 1.5rem 0; // top right bottom left
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  color: $white;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: -.01em;
  text-align: right;
}

.social-icons a {
  margin-left: 1.5rem;
}

@media screen and (min-width: 110rem) {
  .legal-banner-content {
    max-width: 98rem;
  }
}

#comment-button-icon {
  width: 50px;
}

#comment-button-container {
  padding: 5px 5px;
  align-self: center;
}

#comment-container {
  width: 160px;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 60px;
}

.comment-button-label {
  align-self: center;
}

body > div.fade.modal > div {
  display: flex;
}

body > div.contactModalForm.modal {
  display: flex !important;
}
