@import "./colors.scss";

#root-container > table > thead > tr > th:nth-child(1) {
  border-radius: 0px 0px 0px 15px;
}

#root-container > table > thead > tr > th:nth-child(5) {
  border-radius: 0px 0px 15px 0px;
}

table {
  width: 70.9375rem;
  flex-shrink: 0;
  border-radius: 15px;
  background: #D5F0FF;
  margin: 0 auto;
  overflow: hidden;

  thead {
    tr {
      overflow: hidden;
    }

    th {
      width: 74.9375rem;
      height: 4.5rem;
      flex-shrink: 0;
      background: #4F8BB4;
      color: #FFF;
      text-align: center;
      font-family: 'Raleway', sans-serif;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.04688rem;
      position: relative;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.0625rem;
        height: 2.5rem;
        background: rgba(255, 255, 255, 0.40);
      }
    }
  }

  tbody {
    td {
      width: 2rem;
      height: 3.5rem;
      color: #333;
      font-family: 'Open Sans', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      position: relative;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.0625rem;
        height: 14.81263rem;
        // background: rgba(0, 0, 0, 0.20);
      }
    }

    // Alternating row background color
    tr:nth-child(even) {
      background: #BAE6FF;
    }
  }
}
