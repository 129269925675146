@import './colors.scss';

.anequim-container {
  max-width: 86rem !important;
  width: 100% !important;
  padding: 0 3rem 0 3rem !important; // top right bottom left
  margin: 0 auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
  grid-template-areas:
    "profile profile boletin boletin"
    "first-tile second-tile boletin boletin"
    "extra-tile extra-tile extra-tile extra-tile";
    //"extra-tile extra-tile extra-tile calendar-tile";
}

.anequim-container .menu {
  width: 100%;
  margin-left: 0;
  max-width: 60rem;
}

.header {
  color: $textBlue;
  text-align: left;
  font-family: Raleway, sans-serif;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1.25rem;
}

.landing-body {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}

.profile-container {
  grid-area: profile;
  height: 14rem;
  padding: 0 1.5rem 0.5rem 1.5rem; // top right bottom left
  margin-right: 0.5rem;
  background-color: $lightBlue !important;
  border-radius: 1.5rem;
}

.profile-content {
  padding-top: 0.5rem;
  color: $darkGray;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-h {
  color: $textBlue;
  font-family: Raleway, sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.boletin-container {
  grid-area: boletin;
  height: 25rem;
  padding: 0 0.5rem 0.5rem 1.5rem; // top right bottom left
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  background-color: $lightBlue !important;
  border-radius: 1.5rem;
}

.boletin-notes {
  text-align: left;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  color: $darkGray;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.scrollable-content {
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 0.625rem;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.3125rem;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.note-date {
  color: $footerGrey;
  text-align: right !important;
}

.tile {
  display: grid;
  grid-template-columns: repeat(auto-fill, 19.25rem);
  gap: 1rem;
}

.first-tile {
  grid-area: first-tile;
}

.second-tile {
  grid-area: second-tile;
}

.extra-tile {
  grid-area: extra-tile;
}

.home-tile {
  display: flex;
  width: 18.75rem;
  height: 9rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  word-wrap: break-word;
  border-radius: 1.5rem;
  transition: all 0.1s ease-in-out;
}

.extra-tile .home-tile {
  background-color: $lightBlue;
}

.first-tile .home-tile {
  background-color: $coral;
}

.second-tile .home-tile {
  background-color: $btn1Blue;
}

.home-tile a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 4.5rem 2.5rem 0 1.75rem;
  text-align: left;
  max-height: 9.5rem;
  text-decoration: none !important;
}

.tile-image {
  position: absolute;
  top: 0.9rem;
  left: 1.3rem;
  height: 3rem;
  width: auto;
}

.tile-title {
  text-align: left !important;
  font-family: Raleway, sans-serif;
  font-size: 1.125rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tile .tile-title {
  color: $black;
}

.first-tile .tile-title, .second-tile .tile-title {
  color: $white;
}

.tile-description {
  text-align: left;
  font-family: Open Sans, sans-serif;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tile .tile-description {
  color: $darkGray;
}

.first-tile .tile-description, .second-tile .tile-description {
  color: $white;
}

.arrow {
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
}

.first-tile .arrow, .second-tile .arrow {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(115deg) brightness(100%) contrast(100%) !important;
}

.tile .arrow {
  filter: invert(17%) sepia(0%) saturate(0%) hue-rotate(216deg) brightness(100%) contrast(93%);
}

.calendar-tile {
  grid-area: calendar-tile;
  width: 18.75rem;
  height: 9rem;
  background-color: $lightBlue;
  border-radius: 1.5rem;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  word-wrap: break-word;
  padding: 1rem 0 0 0; // top right bottom left
  transition: all 0.1s ease-in-out;
}

// Full Calendar Library CSS
.fc {
  font-family: Raleway, sans-serif;
  padding-top: 3.25rem;
  padding-right: 3.5%;
  padding-left: 1%;
  width: 100%;
  margin: 0 auto;
}

.fc .fc-timegrid-axis {
  display: none !important;
}

.fc .fc-daygrid-day,
.fc .fc-daygrid-day-top,
.fc .fc-daygrid-event,
.fc .fc-col-header-cell {
  background-color: $boxBgBlue !important;
  font-size: 80% !important;
}

.fc .fc-col-header-cell a {
  color: $black !important;
  font-weight: 400;
  font-size: 0.9rem;
}

.fc .fc-event {
  background-color: $btn1Blue !important;
  border: none;
}

.fc .fc-event .fc-event-title {
  color: $white !important;
}

.fc-timegrid .fc-day {
  width: 14.28% !important;
}

.fc .fc-day-today {
  background-color: $peach !important;
}

.fc .fc-col-header-cell.fc-day-today {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.fc .fc-col-header-cell.fc-day-today a {
  color: $white !important;
}

.fc-scroller {
  background-color: $boxBgBlue !important;
  overflow: hidden !important;
}

:root {
  --fc-border-color: $lightBlue;
}

// Smaller screens
@media (max-width: 86rem) {
  .anequim-container {
    padding: 0 1rem 0 1rem !important;
  }

  .home-tile {
    width: 100%;
  }

  .profile-container, .boletin-container {
    width: 100%;
  }

  .tile {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)) !important;
  }

  .first-tile, .second-tile {
    display: flex;
  }

  .calendar-tile {
    display: block; // Prevents calendar bugs
    width: 100%;
  }

  .tile-description {
    font-size: 70%;
  }

  @media (max-width: 68rem) {
    .tile-description {
      display: none;
    }

    .home-tile {
      height: 8.5rem;
    }

    .calendar-tile {
      height: 8.5rem;
    }
  }
}

// Mobile
@media (max-width: 49rem) {
  .anequim-container {
    font-size: 100%;
    padding: 0 1rem 0 1rem !important;
  }

  .grid-container {
    grid-template-areas:
      "profile"
      "boletin"
      "first-tile"
      "second-tile"
      "calendar-tile"
      "extra-tile"
      "extra-tile"
      "extra-tile";
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .profile-container, .boletin-container {
    min-width: 100%;
    width: 100%;
  }

  .boletin-container {
    margin-bottom: 0;
  }

  .tile-description {
    font-size: 0.8125rem;
    display: block;
  }

  .tile {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  }

  .first-tile, .second-tile, .calendar-tile {
    width: 100%;
  }

  .home-tile {
    width: 100%;
  }
}

// Bigger screens
@media screen and (min-width: 110rem) {
  .anequim-container {
    font-size: 110%;
    max-width: 100rem !important;
    padding: 0 4rem 0 4rem !important;
  }

  .grid-container {
    grid-gap: 2rem;
  }

  .anequim-container .menu {
    max-width: 70rem;
  }

  .header {
    font-size: 1.8625rem;
    padding-top: 1.5rem;
  }

  .home-tile {
    width: 21rem;
  }

  .calendar-tile {
    width: 21rem;
  }

  .tile {
    gap: 6.25% !important;
  }
}

//// Old CSS
//.anequim-container {
//  max-width: 80rem !important;
//  width: 100% !important;
//  padding: 0 2.25rem 1.25rem 2.25rem !important; // top right bottom left
//  margin: 0 auto;
//}
//
//.anequim-container .menu {
//  width: 100%;
//  margin-left: 0;
//  max-width: 60rem;
//}
//
//.grid-container {
//  display: grid;
//  grid-template-areas:
//    "profile profile boletin boletin"
//    "first-tile second-tile boletin boletin"
//    "extra-tile extra-tile extra-tile extra-tile";
//  grid-template-rows: auto auto 1fr;
//  grid-template-columns: 1fr 1fr 1fr 1fr;
//  gap: 2rem;
//}
//
//@media (max-width: 43rem) {
//  .anequim-container {
//    padding: 0 1.5rem 0 1.5rem !important; // top right bottom left
//  }
//
//  .grid-container {
//    grid-template-areas:
//      "profile"
//      "boletin"
//      "first-tile"
//      "second-tile"
//      "remaining-tiles";
//    grid-template-columns: 1fr;
//  }
//}
//
//.buffer {
//  margin-bottom: 2rem;
//}
//
//.header {
//  color: $textBlue;
//  text-align: left;
//  font-family: Raleway, sans-serif;
//  font-size: 1.5625rem;
//  font-style: normal;
//  font-weight: 400;
//  line-height: normal;
//  padding-top: 1.25rem;
//}
//
//.body {
//  font-family: 'Open Sans', sans-serif;
//  text-align: left;
//}
//
//.profile {
//  grid-area: profile;
//  min-height: 14rem;
//  padding: 0 1.5rem 0.5rem 1.5rem; // top right bottom left
//  background-color: $lightBlue !important;
//  border-radius: 1.5rem;
//}
//
//.boletin {
//  grid-area: boletin;
//  height: 25rem;
//  padding: 0 1.5rem 0.5rem 1.5rem; // top right bottom left
//  background-color: $lightBlue !important;
//  border-radius: 1.5rem;
//}
//
//.boletin-notes {
//  max-height: 20rem;
//  padding-right: 1rem;
//  overflow: auto;
//  overflow-x: hidden;
//}
//
//.note-date {
//  color: $footerGrey;
//  text-align: right !important;
//}
//
//.first-tile, .second-tile, .extra-tile {
//  display: grid;
//  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//  gap: 1rem;
//}
//
//.first-tile {
//  grid-area: first-tile;
//}
//
//.second-tile {
//  grid-area: second-tile;
//}
//
//.extra-tile {
//  grid-area: remaining-tiles;
//}
//
//.home-tile {
//  display: flex;
//  width: 17.25rem;
//  height: 9rem;
//  justify-content: flex-start;
//  align-items: flex-start;
//  flex-shrink: 0;
//  position: relative;
//  flex-direction: column;
//  cursor: pointer;
//  word-wrap: break-word;
//  padding: 4.5rem 2.5rem 0 1.75rem; // top right bottom left
//  margin: 0 0.625rem 1.25rem;
//  border-radius: 1.5rem;
//  transition: all 0.1s ease-in-out;
//}
//
//@media (max-width: 43rem) {
//  .home-tile {
//    margin: 0;
//  }
//}
//
//.first-tile .home-tile {
//  background-color: $coral;
//}
//
//.second-tile .home-tile {
//  background-color: $btn1Blue;
//}
//
//.extra-tile .home-tile {
//  background-color: $lightBlue;
//}
//
//.home-tile a {
//  text-align: left;
//  max-height: 9.5rem;
//  text-decoration: none !important;
//}
//
//.tile-image {
//  position: absolute;
//  top: 0.9rem;
//  left: 1.3rem;
//  height: 3rem;
//  width: auto;
//}
//
//.tile-title {
//  text-align: left !important;
//  font-family: Raleway, sans-serif;
//  font-size: 1.125rem !important;
//  font-style: normal;
//  font-weight: 400;
//  line-height: normal;
//}
//
//.first-tile .tile-title, .second-tile .tile-title {
//  color: $white !important;
//}
//
//.extra-tile .tile-title {
//  color: $black !important;
//}
//
//.tile-description {
//  text-align: left;
//  font-family: Open Sans, sans-serif;
//  font-size: 0.8125rem;
//  font-style: normal;
//  font-weight: 400;
//  line-height: normal;
//  letter-spacing: -0.04063rem;
//}
//
//.first-tile .tile-description, .second-tile .tile-description {
//  color: $white;
//}
//
//.extra-tile .tile-description {
//  color: $darkGray;
//}
//
//.arrow {
//  position: absolute;
//  bottom: 1.5rem;
//  right: 1rem;
//}
//
//.first-tile .arrow, .second-tile .arrow {
//  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(115deg) brightness(100%) contrast(100%);
//}
//
//.extra-tile .arrow {
//  filter: invert(17%) sepia(0%) saturate(0%) hue-rotate(216deg) brightness(100%) contrast(93%);
//}