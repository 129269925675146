
.employee-info span {
  display: block;
}
.employee-info span label {
  margin-right: 10px;
  font-weight: bold;
}

.my-balance {
  height: 100%;
  text-align: center;
}

.my-balance ul {
  list-style: none;
}

.my-balance label {
  font-weight: bold;
}

.my-balance .column-hdr, .total {
  font-weight: bold;
}

.anequim-bucks-icon-balance{
  max-width: 6%;
  height: auto;
  margin-top: -2px;
}