@import "./colors.scss";

.file-list {
  margin: 5% auto;

  span{
    display: block;
  }

  label {
    padding-left: 15px;
  }

  input {
    margin-right: 5px;
  }
}

.vendor-column {
  min-width: 230px;
}

.name-column {
  min-width: 210px;
}

.employee-column {
  min-width: 200px;
}

.trash-can-icon {
  width: 28px;
}

.delete-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subir-button {
  background-color: $anequimBlue !important;
  border-color: $anequimBlue !important;
}

.subir-button:active,
.subir-button:focus {
  color: $white !important;
}

.back-button {
  position: absolute;
  top: 8rem;
  left: 1rem;
  padding: 0.5rem 1rem;
  background-color: $anequimBlue;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}

.back-button:hover {
  box-shadow: 0 0 0 0.2rem rgba(68, 155, 201, 0.5);
  color: #fff;
  text-decoration: none;
}