// OFFICIAL COLOUR PALETTE
// Primary swatches
$anequimBlue: #449BC9;
$anequimGray: #BCBEC0;

// Secondary swatches
$black: #000000;
$coral: #FF6A4D;
$white: #FFFFFF;
$peach: #F79340;

// Temporary colors
$bgBlue: #EDF9FF;
$lightBlue: #D4F0FF;
$boxBgBlue: #D5F0FF;
$lineBlue: #BDD9ED;
$btn2Blue: #BAE6FF;
$btn1Blue: #4F8BB4;
$secondaryBlue: #4984AD;
$textBlue: #3381AB;
$boxTextBlue: #3E7296;
$titleBlue: #36709A;
$green: #B7DD99;
$darkGray: #333333;


$borderGrey: #707070;
$bgGrey: #F5F5F7;
$footerGrey: #6e6e73;
$socialGrey: #1d1d1f;
$linkBlue: #0070C9;
$btnBlue: #5A9AC5;
$arrowOrange: #EF7357;