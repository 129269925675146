.report-link, .report-link:hover, .report-link:visited {
  cursor: pointer;
  text-decoration: none;
  color: #343a40;
  font-weight: bold;
  font-size: 20px;
}

.date-modal {

}

.report {
  padding: 15px 0 15px 0;
}

#reporting h2 {
  padding: 15px;
  font-weight: bold;
}

#reporting button {
  margin-top: 15px;
}

.headline-reports {
  padding: 15px;
}

#range-pick {
  margin-top: 15px;
}
