@import "./colors.scss";

.sidebar .nav-menu-items li {
  list-style: none;
  span {
    text-decoration: none;
  }
}

.sidebar a, .sidebar a:hover, .sidebar a:visited{
  color: $anequimBlue;
  font-weight: bold;
  text-decoration: none;
}

.menu-bars img {
  max-width: 20%;
}

.sidebar{
  float: left;
  margin-top: 1%;
}

.sidebar li {
  text-align: left;
}

#sidebar .col-md-2 {
  vertical-align: top;
  margin-top: 2%;
}

.sidebar .item-list {
  margin-top: 10%;
}
