@import "./colors.scss";

/* Text alignment for body */
body {
  text-align: center;
}

/* Styling h1 tag */
h1 {
  color: $anequimBlue;
  text-align: center;
}
