.name-column {
  min-width: 210px;
}

.system-column {
  min-width: 200px;
}

.entity-column {
  min-width: 200px;
}

.ref-column {
  min-width: 200px;
}

.trash-can-icon {
  width: 28px;
}

.delete-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
