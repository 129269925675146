.employee-references-content {
  display: inline-block;
  width: 100%;
}

.references-form-container {
  margin-top: 15px;
}

.references-thead {
  background: #5A9AC5;
  color: white;
  font-size: large;
}