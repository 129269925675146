@import './colors.scss';

.navbar {
  background-color: rgba(237, 249, 255, 0.7);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 83.5rem;
  margin: 0 auto;
  padding: 1.25rem 2.25rem;
  flex-grow: 1;
}

.navbar-padding {
  padding-top: 8.88rem;
}

.signout-btn {
  display: flex;
  width: 11.8125rem;
  height: 2.9375rem;
  padding: 1rem 0.6875rem 1rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.875rem;
  background: $anequimBlue;
  color: $white;
  font-family: Raleway, sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.signout-btn:hover {
  color: $white;
}

@media screen and (min-width: 110rem) {
  .navbar-content {
    max-width: 95.5rem;
  }
}

@media (max-width: 86rem)  {
  .navbar-content {
    padding-left: 0;
    padding-right: 0;
  }
}
