@import './colors.scss';

.benefit-page {

  text-align: center;

  img {
    margin: 10px 15px 25px 0;
  }

  .benefit-title {
    display: inline;
  }

  .description {
    margin-top: 25px;
    margin-bottom: 25px;
  }

}

#calc-link {
  color: $anequimBlue;
  cursor: pointer;
}

#calc-link:hover{
  text-decoration: underline;
  cursor: pointer;
}

#confirm-modal {
  position: relative;
  left: 35%;
  top: 35%;
}
