@import './colors.scss';

.headline-balance {
  span {
    text-align: left;
    width: 100%;
    display: block;
  }
  h3{
    height: 30%;
    padding: 5%;
  }
  height: 100%;
}

.benefit-image{
  max-width: 30%;
  height: auto;
}

.benefit-image:hover{
  max-width: 35%;
}

.anequim-bucks-icon{
  max-width: 3.5%;
  height: auto;
  margin-top: -5px;
}

.anequim-bucks-icon-costs{
  max-width: 2.5%;
  height: auto;
}

.benefit-panel {
  span {
    display: block;
    min-height: 3em;
    color: $black;
    font-weight: bold;
    padding-top: 15px;
  }
  a:hover {
    text-decoration: none;
    color: $black;
    font-weight: bold;
  }
}
.page {
  height: 100%;
}

.benefits-display {
  background-color: #f8f9fa;
  height: 100%;
}

.benefits-display h3 {
  color: #6c757d;
  padding: 5%;
}

#top-section  {
  height: 40%;
}

#bottom-section {
  height: 60%;
}

// .progress-container {
//   background-color: $white;
//   min-height: 75px;
//   border: solid 1px $anequimBlue;
//   border-radius: 35px;
// }

// .progress-bar {
//   background-color: $anequimBlue;
//   min-height: 75px;
//   width: attr(data-progress number);
//   border-top-left-radius: 33px;
//   border-bottom-left-radius: 33px;
// }
